.map-container {
	background-color: #f2f2f2;
	width: 100%;

	.no-map {
		padding: 30px;
		text-align: center;
		cursor: pointer;

		.placeholder-icon {
			display: block;
			margin: 30px auto;
			width: 30%;
			height: auto;
		}
	}

	#gmap{
		height:0;
		display: none;
	}

	&.mapActive {

		.no-map {
			display: none;
		}

		#gmap {
			height: 400px;
			display: block;
		}
	}
}
