.team-member.doctor {
	width: 100%;
	overflow: hidden;
	.image {
		float: left;
		width: 30%;

		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}

	.description {
		width: 65%;
		float: right;

		@include media-breakpoint-down(sm) {
			width: 100%;
		}
	}
	margin-bottom: 50px;
}

.team-assistents {
	$box-width: 1.02;
	clear: both;
	width: ($box-width * 100%);
	margin-left: -2%;
	display: block;
	overflow: hidden;

	.team-member.assistant {
		width: (49%/$box-width);
		margin-left: (2%/$box-width);
		margin-bottom: (2%/$box-width);

		@include media-breakpoint-between(md, xl) {
			width: (100%);
			margin-left: 0;
		}

		@include media-breakpoint-down(sm) {
			width: (100%);
			margin-left: 0;
		}
		background-color: #f2f2f2;
		display: block;
		float: left;
		overflow: hidden;

		.image {
			width: 100px;
			float: left;
		}

		.description {
			float: left;
			margin-left: 15px;
			margin-top: 15px;

			.name {
				font-weight: $webfont-weight-bold;
			}


			.position {
				color: gray;
			}
		}
	}



}
