.section {
	min-width: 100%;
	min-height: 100vh;
	position: relative;
	background-color: white;
	overflow: hidden;

	.section-header {
		min-height: 100vh;
		background-color: #037cb4;
		color: white;
		overflow: hidden;
		box-sizing: border-box;
		position: absolute;
		background-size: cover;
		background-position: center;
		@include media-breakpoint-down(md) {
			height: 85vh;
		}

		.content-box {
			margin-top: 45px;
		}
	}

	&.pinning {
		.section-header {
			position: fixed;
			top: 0;
		}
	}

	.section-body {
		overflow: hidden;
		box-sizing: border-box;
		height: auto;
		background-color: white;

		@include media-breakpoint-down(md) {
			position: relative;
			top: 85vh;
			margin-bottom: 85vh;
		}
	}

	.section-header,
	.section-body {
		width: 50vw;
		padding: 50px;

		@include media-breakpoint-down(md) {
			width: 100vw;
			padding: 35px;
		}

		@include media-breakpoint-down(sm) {
			padding: 20px;
		}
	}

	&.content-left {
		.section-header {
			right: 0;
			float: right;
			@include media-breakpoint-down(md) {
				float: none;
			}

		}
		.section-body {
			float: left;
			@include media-breakpoint-down(md) {
				float: none;
			}
		}
	}

	&.content-right {
		.section-header {
			left: 0;
			float: left;
			@include media-breakpoint-down(md) {
				float: none;
			}
		}
		.section-body {
			float: right;
			@include media-breakpoint-down(md) {
				float: none;
			}
		}
	}
}

//.section-pin {
//	width: 50% !important;
//	height: 100vh !important;
//
//
//	.section-header,
//	.section-body {
//		width: 100% !important;
//	}
//}
//
//.pinning {
//	.section-pin {
//		width: 100% !important;
//	}
//
//	.section-header,
//	.section-body {
//		width: 50% !important;
//	}
//
//	&.content-left {
//		.section-header {
//			margin-left: 50% !important;
//		}
//	}
//}
