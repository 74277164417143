@import "Providers/Webfont";


@import "Modules/Globals";
@import "Modules/Breakpoints";
@import "Modules/Menu";
@import "Modules/Panels";
@import "Modules/Type";
@import "Modules/Accordion";
@import "Modules/ImagesRooms";
@import "Modules/ImagesTeam";
@import "Modules/Form";
@import "Modules/GoogleMaps";
@import "Modules/Klaro";

@import "Providers/Hamburgers";


.box-warning {
	background-color: #f1f1f1;
	padding: 30px;
	margin-bottom: 50px;

	h4 {
		color: #970400;
		display: block;
		font-size: 1.6rem;
		border-bottom: 1px solid #970400;
		padding-top: 0;
		margin-top:0;
		padding-bottom: 15px;
		margin-bottom: 15px;


		@include media-breakpoint-down(md) {
			font-size: 1.5rem;
			line-height: 1.5*1.4rem;
		}

		@include media-breakpoint-down(sm) {
			font-size: 1.25rem;
			line-height: 1.25*1.4rem;
		}
	}
}


img {
	max-width: 100%;
	height: auto;
}

.text-center {
	text-align: center;
}

.margin-top-medium {
	margin-top: 3rem;
}

.margin-top-small {
	margin-top: 1.5rem;
}

.bg-dummy {
	background-image: url("../gfx/dummy_background.jpg");
}

.bg-header {
	background-image: url("../gfx/background_header.jpg");
}

.bg-treatments{
	background-image: url("../gfx/behandlungen.jpg");
}

.bg-team{
	background-image: url("../gfx/team.jpg");
}

