body {
	font-size: 17px;
	line-height: 1.41em;
}

h1 {
	font-weight: $webfont-weight-regular;
	font-size: 4.0rem;
	line-height: 4*1.4rem;

	small {
		font-weight: $webfont-weight-thin;
	}

	@include media-breakpoint-down(md) {
		font-size: 2.5rem;
		line-height: 2.5*1.4rem;
	}

	@include media-breakpoint-down(sm) {
		font-size: 2rem;
		line-height: 2*1.4rem;
	}
}

h2 {
	font-weight: $webfont-weight-regular;
	font-size: 3.5rem;
	line-height: 3.5*1.4rem;


	@include media-breakpoint-down(md) {
		font-size: 2rem;
		line-height: 2*1.4rem;
	}

	@include media-breakpoint-down(sm) {
		font-size: 1.5rem;
		line-height: 1.5*1.4rem;
	}
}

h3 {
	font-size: 2.5rem;
	font-weight: $webfont-weight-regular;
	color: #037cb4;
	line-height: 2.5*1.4rem;


	@include media-breakpoint-down(md) {
		font-size: 1.5rem;
		line-height: 1.5*1.4rem;
	}

	@include media-breakpoint-down(sm) {
		font-size: 1.25rem;
		line-height: 1.25*1.4rem;
	}
}

a {
	color: #037cb4;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
		color: #00384e;
	}
}


.copyright {
	margin-top: 40px;
	font-size: 0.7rem;
	color: silver;

	a {
		text-decoration: underline;
		color: silver;
		&:hover {
			text-decoration: none;
			color: gray;
		}
	}
}
