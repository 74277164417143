#menu {
	position: fixed;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.7);
	z-index: 5000;
	/*border-bottom: 1px solid rgba(3, 124, 180, 0.9);*/
	padding-top: 20px;
	padding-bottom: 20px;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);

	button {
		margin-left: 50px;

		@include media-breakpoint-down(md) {
			margin-left: 35px;
		}

		@include media-breakpoint-down(sm) {
			margin-left: 20px;
		}

		@include media-breakpoint-up(md) {
			display: none;
		}
	}

	ul {
		padding: 0;
		margin: 0;
		display: block;
		list-style: none;
		overflow: hidden;
		margin-left: 50px;

		@include media-breakpoint-down(md) {
			display: none;
			margin-left: 35px;
		}

		@include media-breakpoint-down(sm) {
			margin-left: 20px;
		}

		li {
			padding: 0;
			margin: 0;
			float: left;
			margin-right: 30px;

			@include media-breakpoint-down(md) {
				display: block;
				width: 100%;
				margin: 0;
			}

			a {
				color: black;

				&:hover {
					color: #037cb4;
					text-decoration: none;
				}
			}
		}
	}

	&.expand-mobile {
		@include media-breakpoint-down(md) {
			height: 100%;
			overflow: scroll;
		}

		ul {
			display: block;
		}
	}
}
