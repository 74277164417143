/* latin-ext */
@font-face {
	font-family: 'Public Sans';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(../Fonts/ijwRs572Xtc6ZYQws9YVwnNIfJ7QwOk1Fig.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Public Sans';
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src: url(../Fonts/ijwRs572Xtc6ZYQws9YVwnNGfJ7QwOk1.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
	font-family: 'Public Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../Fonts/ijwRs572Xtc6ZYQws9YVwnNIfJ7QwOk1Fig.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-family: 'Public Sans';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url(../Fonts/ijwRs572Xtc6ZYQws9YVwnNGfJ7QwOk1.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


$webfont-weight-thin: 100;
$webfont-weight-extra-light: 200;
$webfont-weight-light:  300;
$webfont-weight-regular: 400;
$webfont-weight-medium: 500;
$webfont-weight-semibold: 600;
$webfont-weight-bold: 700;
$webfont-weight-extrabold: 800;
$webfont-weight-bold: 900;
