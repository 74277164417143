.accordion {
	dt {
		display: block;
		color: white;
		background-color: #037cb4;
		margin-top: 30px;
		&:first-child {
			margin-top: 0;
		}
		padding: 15px;
		cursor: pointer;
	}

	dd {
		background-color: #f2f2f2;
		margin: 0;
		padding: 15px;
	}
}
