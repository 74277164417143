.form-container {
	.field {
		margin-bottom: 15px;

		label {
			display: block;
		}

		input,
		textarea {
			display: block;
			width: 100%;
			padding: 4px 8px;
			box-sizing: border-box;
			border: 1px solid #037cb4;
			font-size: 1rem;

			&.error {
				border-color: darkred;
			}
		}

		input[type='checkbox'] {
			display: inline;
			width: auto;
			padding: 0;
		}

		textarea {
			height: 200px;
			font-family: 'Public Sans', sans-serif;;
		}

		&:last-child {
			margin-bottom: 0;
		}

		button {
			background-color: #037cb4;
			border: none;
			color: white;
			padding: 6px 10px;
			text-align: center;
			text-decoration: none;
			display: inline-block;
			font-size: 1rem;
			cursor: pointer;
		}
	}

	#form-messages {
		&.error {
			background-color: darkred;
			color: white;
		}

		&.success {
			background-color: #00b50c;
			color: white;
		}

		&.error,
		&.success {
			padding: 15px;
			width: 100%;
			margin-bottom: 15px;
			box-sizing: border-box;
		}
	}


	/*Huge thanks to @tobiasahlin at http://tobiasahlin.com/spinkit/ */
	.spinner {
		width: 70px;
		display: inline-block;

		&.d-none {
			display: none;
		}
	}

	.spinner > div {
		width: 18px;
		height: 18px;
		background-color: #037cb4;

		border-radius: 100%;
		display: inline-block;
		-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	}

	.spinner .bounce1 {
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}

	.spinner .bounce2 {
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}

	@-webkit-keyframes sk-bouncedelay {
		0%, 80%, 100% { -webkit-transform: scale(0) }
		40% { -webkit-transform: scale(1.0) }
	}

	@keyframes sk-bouncedelay {
		0%, 80%, 100% {
			-webkit-transform: scale(0);
			transform: scale(0);
		} 40% {
			  -webkit-transform: scale(1.0);
			  transform: scale(1.0);
		  }
	}
}
