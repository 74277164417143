.imageshow {
	margin-top: 8%;

	.imagelist {
		padding: 0;
		margin: 0;
		width: 108%;
		display: block;
		list-style: none;
		margin-left: (-4%/1.08);

		.img-item {
			padding: 0;
			display: inline-block;
			margin: (4%/1.08);

			 img {
				 width: 100%;
				 height: auto;
			 }
		}

		.img-item.img-landscape {
			width: (63%/1.08);

			@include media-breakpoint-between(md, xl) {
				width: (100%/1.08);
			}

			@include media-breakpoint-down(sm) {
				width: (100%/1.08);
			}
		}

		.img-item.img-portrait {
			width: (28%/1.08);

			@include media-breakpoint-between(md, xl) {
				width: (45%/1.08);
			}

			@include media-breakpoint-down(sm) {
				width: (45%/1.08);
			}
		}
	}
}
